.om-sliderrange {
  min-height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.om-sliderrange-range {
  height: 4px;
  position: relative;
  margin: 8px 4px;
}

.om-sliderrange-display {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.om-sliderrange-range:before {
  display: block;
  content: "";
  height: 4px;
  border-radius: 4px;
  background: #c2c0c2;
}

.om-sliderrange-range-indicator {
  height: 4px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: Highlight;
}

.om-sliderrange-button-start,
.om-sliderrange-button-end {
  cursor: pointer;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #7a7a7a;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: visible;
}
